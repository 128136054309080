
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class FormCollapse extends Vue {
  @Prop({ default: false }) isOpen: boolean;
  @Prop({ default: '' }) name: string;
  @Prop({ default: false }) disabled: boolean;

  get openString() {
    return this.isOpen.toString();
  }

  openToggle() {
    if (!this.disabled) {
      this.toggleSection();
    }
  }

  @Emit()
  toggleSection() {
    return this.name;
  }
}
