
import { Component, Mixins } from 'vue-property-decorator';
import ApplicationPage from '@/components/registration/ApplicationPage.vue';
import { applications } from '@/store/modules/application';
import { progress } from '@/store/modules/progress';
import OfferCard from '@/components/registration/OfferCard.vue';
import { noCurrencyMoney } from '@/core/plugins/filters';
import { REGISTRATION_ROUTES } from '@/router/routes_type';
import ApplicationOfferMixin from '@/mixins/ApplicationOffer.vue';
import { getRouteByApplication } from '@/router/router';
import { SEND_PROGRESS } from '@/router/routes';

@Component({
  name: 'RegistrationDecreasePage',
  components: { ApplicationPage, OfferCard }
})
export default class RegistrationOfferDecreasePage extends Mixins(ApplicationOfferMixin) {
  acceptLoading = false;
  cancelLoading = false;

  mounted() {
    progress.set('85');
  }

  get cancelBtnStyles() {
    return {
      color: 'gray',
      'text-decoration': 'underline'
    };
  }

  get loanAmount() {
    return noCurrencyMoney(this.offer?.loanAmount ?? '');
  }

  get offer() {
    return applications.proposedOfferVariation;
  }

  async declineOffer() {
    if (this.cancelLoading) {
      return;
    }
    this.cancelLoading = true;
    await applications.initGetApplications();
    const route = await this.checkRouteBeforeProgress(REGISTRATION_ROUTES.OFFER_DECREASE_PAGE);
    if (route) return this.redirect(route);

    await this.performAction(
      applications.declineOffer,
      loading => (this.cancelLoading = loading),
      REGISTRATION_ROUTES.APPLICATION_REJECT_PAGE
    );
  }

  async approveOffer() {
    if (this.acceptLoading) {
      return;
    }

    this.acceptLoading = true;
    await applications.initGetApplications();
    if (applications.lastApplication) {
      const route = getRouteByApplication(applications.lastApplication);
      if (route && route !== REGISTRATION_ROUTES.OFFER_DECREASE_PAGE) {
        return this.redirect(route, { SEND_PROGRESS: SEND_PROGRESS });
      }
    }

    await this.performAction(
      applications.acceptOffer,
      loading => (this.acceptLoading = loading),
      REGISTRATION_ROUTES.MANUAL_VERIFICATION_PAGE
    );
  }
}
