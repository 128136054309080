
import { Component, Mixins } from 'vue-property-decorator';
import ApplicationPage from '@/components/registration/ApplicationPage.vue';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';

import DisbursementType from '@/components/registration/DisbursementType.vue';
import { MULTI_LOAN_ROUTES } from '@/router/routes_type';
import { SEND_PROGRESS } from '@/router/routes';
import { progress } from '@/store/modules/progress';

@Component({
  name: 'MultiLoanDisbursementTypePage',
  components: {
    ApplicationPage,
    DisbursementType
  }
})
export default class MultiLoanDisbursementTypePage extends Mixins(CheckRoutingMixin) {
  page = MULTI_LOAN_ROUTES.DISBURSEMENT_TYPE_PAGE;

  mounted() {
    progress.set('55');
  }

  async submit() {
    await this.redirect(MULTI_LOAN_ROUTES.INCOME_PAGE, { SEND_PROGRESS: SEND_PROGRESS }, true);
  }
}
