
import { Component, Prop, Vue } from 'vue-property-decorator';
import { OfferVariation } from '@/api/models/application';

@Component({
  name: 'OfferCard'
})
export default class OfferCard extends Vue {
  @Prop({ required: true }) private offerVariation: OfferVariation;
}
