
import { Component, Prop, Vue } from 'vue-property-decorator';
import LmsInput from '@/components/registration/FieldInput.vue';
import LmsForm from '@/components/registration/Form.vue';
import { VERIFICATION_STATE } from '@/api/models/verification';
import { TBankCardVerificationState } from '@/services/bankCard';

@Component({
  name: 'LmsBankCardPreview',
  components: { LmsInput, LmsForm }
})
export default class BankCardAttachment extends Vue {
  @Prop({ required: true })
  private verification!: TBankCardVerificationState;

  get cardMask() {
    return this.verification?.receiptStamp?.panMasked;
  }

  get isConfirmed() {
    return this.verification?.state === VERIFICATION_STATE.CONFIRMED;
  }

  get isExpired() {
    return this.verification?.state === VERIFICATION_STATE.EXPIRED;
  }

  get isNew() {
    return this.verification?.state === VERIFICATION_STATE.NEW;
  }

  get labelText() {
    switch (true) {
      case this.isExpired:
        return this.$t('FORM.FIELDS.disbursement.data.bankCardNumber.EXPIRED.LABEL');
      case this.isConfirmed:
        return this.$t('FORM.FIELDS.disbursement.data.bankCardNumber.CONFIRMED.LABEL');
      default:
        return this.$t('FORM.FIELDS.disbursement.data.bankCardNumber.REMOVE.LABEL');
    }
  }

  get buttonText() {
    switch (true) {
      case this.isExpired:
        return this.$t('FORM.FIELDS.disbursement.data.bankCardNumber.EXPIRED.BUTTON');
      case this.isConfirmed:
        return this.$t('FORM.FIELDS.disbursement.data.bankCardNumber.CONFIRMED.BUTTON');
      default:
        return this.$t('FORM.FIELDS.disbursement.data.bankCardNumber.REMOVE.BUTTON');
    }
  }

  get button() {
    const labelKey = this.isConfirmed
      ? 'FORM.FIELDS.disbursement.data.bankCardNumber.CONFIRMED.LABEL'
      : 'FORM.FIELDS.disbursement.data.bankCardNumber.REMOVE.LABEL';
    return this.$t(labelKey);
  }

  get dialogTitle() {
    return this.$t('FORM.FIELDS.disbursement.data.bankCardNumber.REMOVE.CONFIRM_TITLE').toString();
  }

  get dialogContents() {
    return this.$t('FORM.FIELDS.disbursement.data.bankCardNumber.REMOVE.CONFIRM_TEXT').toString();
  }

  get confirmButtonText() {
    return this.$t('FORM.FIELDS.disbursement.data.bankCardNumber.REMOVE.CONFIRM_SUBMIT').toString();
  }

  get cancelButtonText() {
    return this.$t('FORM.FIELDS.disbursement.data.bankCardNumber.REMOVE.CONFIRM_CANCEL').toString();
  }

  handleRemove() {
    this.$confirm(this.dialogContents, this.dialogTitle, {
      confirmButtonText: this.confirmButtonText,
      cancelButtonText: this.cancelButtonText,
      type: 'warning'
    }).then(() => {
      this.$emit('unlink');
      this.$notify.success(this.$t('NOTIFICATION.CARD_UNILNK.SUCCESS').toString());
    });
  }
}
