
import { Mixins, Prop } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';
import {
  ageValidator,
  isDateFuture,
  isDatePast,
  passportIdFormat,
  required
} from '@/components/registration/settings/rules';
import { cyrillicMask, passportMask } from '@/components/registration/settings/mask';
import LmsInput from '@/components/registration/FieldInput.vue';
import LmsSelect from '@/components/registration/FieldSelect.vue';
import LmsDateInput from '@/components/registration/FieldDateInput.vue';
import i18n from '@/core/plugins/vue-i18n';
import LmsForm from '@/components/registration/Form.vue';
import { ContentService } from '@/services/content';
import { SECTIONS } from '@/store/modules/models/content';
import { REGISTRATION_ROUTES } from '@/router/routes_type';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';
import { addStep, buildUiStepState } from '@/api/progress';
import { PersonalData as PersonalDataModel, GENDER } from '@/api/models/client';
import { UI_CLIENT_FLOW_STEP_NAME } from '@/api/models/progress';
import { LmsFormState } from '@/components/registration/models/form';
import { AdditionalInfoSection } from '@/views/registration/AdditionalInfo/ApplicationAdditionalInfo.config';
import { ActionFailedViolation } from '@/utils/request';
import { set } from 'lodash-es';
import { client } from '@/store/modules/client';

@Component({
  name: 'PersonalData',
  components: {
    LmsForm,
    LmsInput,
    LmsSelect,
    LmsDateInput
  },
  methods: { isDateFuture, isDatePast, passportIdFormat, required, ageValidator, cyrillicMask }
})
export default class PersonalData extends Mixins(CheckRoutingMixin) {
  @Prop({ default: true })
  loading: boolean;

  personalData: PersonalDataModel = client.personalData;

  get genderOptions() {
    return () =>
      Object.values(GENDER).map(value => {
        return {
          value,
          label: i18n.t(`FORM.OPTIONS.gender.${value}`).toString()
        };
      });
  }

  get issuedByOptions() {
    return () => ContentService.sortedOptions(SECTIONS.IDENTITY_DOCUMENT_ISSUED_BY);
  }

  get passportMask() {
    return passportMask;
  }

  setValue(field: string, value: any) {
    set(this.personalData, field, value);
  }

  async submitForm() {
    this.$emit('update:loading', true);

    const route = await this.checkRouteBeforeProgress(REGISTRATION_ROUTES.ADDITIONAL_INFO);
    if (route) return this.redirect(route);

    try {
      await client.updatePersonalData(this.personalData);
      await addStep(buildUiStepState(UI_CLIENT_FLOW_STEP_NAME.PERSONAL_DATA));
      this.$emit('submit');
    } catch (response) {
      const currentForm = this.$refs[AdditionalInfoSection.PERSONAL_DATA] as LmsFormState;
      const violations: ActionFailedViolation[] = response.data.violations || [];

      currentForm.setErrors(violations);
      this.$emit('update:loading', false);
    }
  }
}
