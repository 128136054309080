
import { Component, Mixins, Watch } from 'vue-property-decorator';
import ApplicationPage from '@/components/registration/ApplicationPage.vue';
import { progress } from '@/store/modules/progress';
import { agreements } from '@/store/modules/agreements';
import { PROFILE_ROUTES, REGISTRATION_ROUTES } from '@/router/routes_type';
import { AGREEMENT_CONTRACT_STATUS } from '@/api/models/agreements';
import { SEND_PROGRESS, SKIP_ROUTE_CHECK } from '@/router/routes';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';
import { getRouteByAgreement } from '@/router/router';

const POOLING_INTERVAL = 5000;

const APPROVED_CONTRACT_STATUS_LIST = [AGREEMENT_CONTRACT_STATUS.APPROVED, AGREEMENT_CONTRACT_STATUS.ISSUED];
const REJECT_CONTRACT_STATUS_LIST = [AGREEMENT_CONTRACT_STATUS.REJECTED, AGREEMENT_CONTRACT_STATUS.CANCELLED];

@Component({
  name: 'RegistrationAgreementSigningPage',
  components: { ApplicationPage }
})
export default class RegistrationAgreementSigningPage extends Mixins(CheckRoutingMixin) {
  private redirectTimeout: number;

  @Watch('progress', { immediate: true })
  setProgress() {
    progress.set('95');
  }

  created() {
    this.checkAgreementSigned();
  }

  checkAgreementSigned() {
    this.redirectTimeout = setTimeout(async () => {
      if (!(await agreements.initGetAgreementList({ force: true }))) {
        return;
      }

      let agreement = agreements.lastAgreement;
      if (agreement?.contractStatus && APPROVED_CONTRACT_STATUS_LIST.includes(agreement.contractStatus)) {
        return this.redirect(PROFILE_ROUTES.MY_LOAN_PAGE, { SEND_PROGRESS: SEND_PROGRESS });
      }

      const route = getRouteByAgreement(agreement);
      if (route && route !== REGISTRATION_ROUTES.AGREEMENT_SIGNING_PAGE) {
        return this.redirect(route);
      }

      if (agreement?.contractStatus && REJECT_CONTRACT_STATUS_LIST.includes(agreement.contractStatus)) {
        return this.redirect(REGISTRATION_ROUTES.APPLICATION_REJECT_PAGE, {
          SEND_PROGRESS: SEND_PROGRESS,
          SKIP_ROUTE_CHECK: SKIP_ROUTE_CHECK
        });
      }

      return this.checkAgreementSigned();
    }, POOLING_INTERVAL);
  }

  beforeDestroy() {
    clearTimeout(this.redirectTimeout);
  }
}
