import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { ActionFailedViolation } from '@/utils/request';

export interface ErrorsState {
  error: string | null;
}

@Module({ name: 'errors', store, dynamic: true })
class Errors extends VuexModule implements ErrorsState {
  error: string | null = null;
  violations: ActionFailedViolation[] = [];

  @Mutation
  public clearError() {
    this.error = null;
    this.violations = [];
  }

  @Mutation
  public setError(error: string) {
    this.error = error;
  }

  @Mutation
  public setViolations(violations: ActionFailedViolation[], error: string) {
    this.violations = violations;
    this.error = error;
  }
}

export const errors = getModule(Errors);
