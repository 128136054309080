import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { ScheduleResponse } from '@/api/models/product';
import { getProductSchedule } from '@/api/product';
import axios, { CancelTokenSource } from 'axios';
import { DATE_PATTERN, PRODUCT } from '@/core/config/setup/product';
import dayjs from 'dayjs';
import { calculator } from '@/store/modules/calculator';
import { agreements } from '@/store/modules/agreements';
import { client } from '@/store/modules/client';

type TScheduleState = ScheduleResponse | null;

const MAX_MONTH_PAYMENT_DAY_NUMBER = 28;
const CancelToken = axios.CancelToken;

let cancelSource: CancelTokenSource | null = null;

const calcPaymentDate: (now: dayjs.Dayjs) => number = now => {
  const day = Math.min(now.get('date') - 1, MAX_MONTH_PAYMENT_DAY_NUMBER);

  return day < 1 ? MAX_MONTH_PAYMENT_DAY_NUMBER : day;
};

@Module({ name: 'schedule', store, dynamic: true })
class Schedule extends VuexModule {
  loading = true;
  private schedules = {
    [PRODUCT.REGULAR]: null as TScheduleState,
    [PRODUCT.INSURANCE]: null as TScheduleState
  };

  get hasSchedule(): boolean {
    return this.currentSchedule !== null;
  }

  get currentSchedule(): TScheduleState {
    return this.schedules[calculator.selectedProductType] ?? null;
  }

  get averagePayment() {
    return this.currentSchedule?.result.summary.annuityPayment ?? '';
  }

  get insuranceCost(): number {
    return Math.round(Number(this.schedules[PRODUCT.INSURANCE]?.result.summary.commissionAmount ?? ''));
  }

  get lastPaymentDate() {
    const payment = this.currentSchedule?.result.instalments.slice(-1)[0];

    return payment?.period.endDate ?? '';
  }

  @Action({ rawError: true })
  async loadSchedules() {
    cancelSource?.cancel('Cancelled subsequent schedule request');

    if (client.authorized) {
      await agreements.initGetAgreementList();
    }

    cancelSource = CancelToken.source();
    const now = dayjs();
    const paymentDayOfMonth = this.agreementPaymentDay ?? calcPaymentDate(now);

    const requests = [PRODUCT.REGULAR, PRODUCT.INSURANCE].map(type =>
      getProductSchedule(
        {
          alias: type,
          amount: calculator.amount!.toString(10),
          conditions: {
            term: calculator.term!,
            startDate: now.format(DATE_PATTERN),
            paymentDayOfMonth
          }
        },
        { cancelToken: cancelSource?.token }
      )
        .then(({ data: schedule }) => this.setSchedule({ type, schedule }))
        .catch(e => {
          !axios.isCancel(e) && this.setSchedule({ type, schedule: null });
          throw e;
        })
    );

    this.setLoading(true);
    try {
      await Promise.all(requests);
    } finally {
      this.setLoading(false);
      cancelSource = null;
    }
  }

  @Mutation
  private setSchedule(state: { type: PRODUCT; schedule: TScheduleState }) {
    this.schedules[state.type] = state.schedule;
  }

  @Mutation
  private setLoading(loading: boolean) {
    this.loading = loading;
  }

  private get agreementPaymentDay(): number | null {
    return agreements.getActiveAgreements[0]?.calculatorInput.paymentDay ?? null;
  }
}

export const schedule = getModule(Schedule);
