import i18n from '@/core/plugins/vue-i18n';
import { content } from '@/store/modules/content';
import { SECTIONS } from '@/store/modules/models/content';

export const ContentService = new (class {
  async sortedOptions(section: SECTIONS) {
    return this.options(section).then(options => options.sort((a, b) => (a.label < b.label ? -1 : 1)));
  }

  async optionsList(section: SECTIONS) {
    await content.loadContentSection({ section, locale: i18n.locale });
    const options = content.contentSection(section, i18n.locale);
    if (options) {
      return Object.entries(options).map(([id, option]) => {
        return {
          label: String(Object(option).name),
          value: id
        };
      });
    }
    return [];
  }

  async options(section: SECTIONS) {
    await content.loadContentSection({ section, locale: i18n.locale });
    const options = content.contentSection(section, i18n.locale);
    if (options) {
      return Object.entries(options).map(([id, name]) => ({ label: String(name), value: id }));
    }
    return [];
  }

  async value(section: SECTIONS) {
    await content.loadContentSection({ section, locale: i18n.locale });
    const value = content.contentSection(section, i18n.locale);

    return value ? String(value.data) : '';
  }
})();
