
import { Component, Emit, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import LmsForm from '@/components/registration/Form.vue';
import LmsCalculatorSlider from '@/components/calculator/CalculatorSlider.vue';
import LmsCalculatorMetrixSlider from '@/components/calculator/CalculatorMetrixSlider.vue';
import LmsFieldSelect from '@/components/registration/FieldSelect.vue';
import { calculator } from '@/store/modules/calculator';
import Schedule from '@/components/schedule/Schedule.vue';
import { schedule } from '@/store/modules/schedule';
import { FORM_ERRORS, required } from '@/components/registration/settings/rules';
import LmsRadioButtons from '@/components/registration/FieldRadioButtons.vue';
import dayjs from 'dayjs';
import { DATE_PATTERN, PRODUCT } from '@/core/config/setup/product';
import { RadioButton } from '@/components/registration/models/radio-button';
import { SelectSetting } from '@/components/registration/models/select';
import { LmsFormState } from '@/components/registration/models/form';
import { ActionFailedViolation } from '@/utils/request';
import { money } from '@/core/plugins/filters';
import { ContentService } from '@/services/content';
import { SECTIONS } from '@/store/modules/models/content';
import { client } from '@/store/modules/client';
import { applications } from '@/store/modules/application';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';
import { ROUTE } from '@/router/routes_type';
import { errors } from '@/store/modules/errors';
import { Product } from '@/api/models/product';
import { storeCalculation } from '@/router/router';
import { Debounce } from '@/decorators/Debounce';

interface FormData {
  amount: number;
  term: number;
  purpose: string;
}

export interface Calculation {
  loan: FormData;
  product: {
    id: string;
    revision: Product['revision'];
  };
}

@Component({
  methods: { required },
  components: {
    LmsForm,
    Schedule,
    LmsFieldSelect,
    LmsRadioButtons,
    LmsCalculatorSlider,
    LmsCalculatorMetrixSlider
  },
  name: 'LmsCalculator'
})
export default class CalculatorComponent extends Mixins(CheckRoutingMixin) {
  @Prop({ default: false })
  productSelected!: boolean;
  @Prop({ default: false })
  loading!: boolean;
  @Prop({ required: true })
  page: ROUTE;

  @Ref('calculatorForm') form!: LmsFormState;

  purposeSetting: SelectSetting = {
    options: () => ContentService.sortedOptions(SECTIONS.LOAN_PURPOSE)
  };

  selectRules = { required };

  get currentSchedule() {
    return schedule.currentSchedule;
  }

  get scheduleLoading() {
    return schedule.loading;
  }

  created() {
    applications.initGetApplications();
    this.updateSchedule();
  }

  // Dom refs can not be accessed before component is mounted
  mounted() {
    if (errors.violations.length > 0) {
      this.setFormErrors(errors.violations);
      errors.clearError();
    } else if (errors.error) {
      this.setFormErrors(undefined, errors.error);
      errors.clearError();
    }
  }

  get sliderSettings() {
    return calculator.sliderSetting;
  }

  get amount() {
    return calculator.amount ?? 0;
  }

  get term() {
    return calculator.term ?? 0;
  }

  get activeProduct() {
    return calculator.selectedProductType;
  }

  get availableCredit() {
    return client.availableCredit ? Number(client.availableCredit) : 0;
  }

  get amountIsOverAvailableCredit() {
    if (this.firstApplication) {
      return false;
    }

    return !this.amount || this.amount > this.availableCredit;
  }

  get firstApplication() {
    return !applications.getApplications.length;
  }

  get amountErrorMessage(): string {
    if (this.amountIsOverAvailableCredit) {
      return this.$t(`FORM.${FORM_ERRORS.FIELD_ERROR_AVAILABLE_CREDIT}`, {
        loanAmount: money(this.amount),
        availableCredit: money(this.availableCredit)
      }).toString();
    }

    return '';
  }

  get submitDisable() {
    return this.amountIsOverAvailableCredit || !schedule.hasSchedule;
  }

  get productsRadio(): RadioButton[] {
    const insuranceCost = money(schedule.insuranceCost);
    const totalPrincipal = schedule.currentSchedule?.result.summary.issuedAmount || '';

    const lastDay = schedule.lastPaymentDate;
    const validity = dayjs(lastDay).format(DATE_PATTERN);

    return [
      {
        name: PRODUCT.INSURANCE,
        label: 'FIELD_PRODUCT_INSURANCE_LABEL',
        value: PRODUCT.INSURANCE,
        info: this.$t('FORM.FIELD_PRODUCT_INSURANCE_INFO', {
          insuranceCost,
          validity,
          totalPrincipal
        }).toString()
      },
      {
        name: PRODUCT.REGULAR,
        label: 'FIELD_PRODUCT_REGULAR_LABEL',
        value: PRODUCT.REGULAR,
        info: this.$t('FORM.FIELD_PRODUCT_REGULAR_INFO').toString()
      }
    ];
  }

  @Watch('amount')
  async watchAmount() {
    await this.updateSchedule();
  }

  @Watch('term')
  async watchTerm() {
    await this.updateSchedule();
  }

  setAmount(amount: number) {
    calculator.setAmount(amount);
  }

  setTerm(term: number) {
    calculator.setTerm(term);
  }

  chooseProduct(type: PRODUCT) {
    calculator.chooseProduct(type);
  }

  setFormErrors(violations?: ActionFailedViolation[], details?: string) {
    this.form.setErrors(violations, details);
  }

  @Debounce(100) // Multiple watchers change at the same time
  async updateSchedule() {
    try {
      await schedule.loadSchedules();
    } catch (e) {
      calculator.resetSliders();
    }
  }

  // Destruct since there are more field than we need
  async submitHandler({ amount, term, purpose }: FormData) {
    if (this.submitDisable || !calculator.selectedProduct) {
      return;
    }

    this.load();
    const route = await this.checkRouteBeforeProgress(this.page);
    if (route) return this.redirect(route);

    const { id, revision } = calculator.selectedProduct;

    const calculation: Calculation = {
      loan: { amount, term, purpose },
      product: { id, revision }
    };

    storeCalculation(calculation);

    this.submit();
  }

  @Emit()
  load() {
    return true;
  }

  @Emit()
  submit() {
    return;
  }
}
