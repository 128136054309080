
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import LmsDocumentImagePreviewPopup from '@/components/DocumentImageInput/DocumentImagePreviewPopup.vue';
import { UPLOAD_STATUS } from './DocumentImageUpload.config';

@Component({
  name: 'LmsDocumentImagePreview',
  components: { LmsDocumentImagePreviewPopup }
})
export default class DocumentImagePreviewComponent extends Vue {
  @Prop() private errorMessage!: string;
  @Prop({ required: true }) private documentUrl!: string;
  @Prop({ default: 100 }) private percentage!: number;
  @Prop({ default: UPLOAD_STATUS.READY }) private status: UPLOAD_STATUS;
  @Prop() private placeholder!: string;

  dialogVisible = false;
  dataURI = '';
  loading = false;

  $refs!: {
    thumb?: HTMLDivElement;
  };

  get isPushing() {
    return this.status === UPLOAD_STATUS.STARTED;
  }

  get isCompleted() {
    return this.status === UPLOAD_STATUS.COMPLETED;
  }

  get isSuccess() {
    return this.status === UPLOAD_STATUS.SUCCESS;
  }

  get isReady() {
    return this.status === UPLOAD_STATUS.READY;
  }

  get isError() {
    return this.status === UPLOAD_STATUS.ERROR;
  }

  progressFormat() {
    return '';
  }

  @Watch('documentUrl', { immediate: true })
  async thumbBlob() {
    if (this.documentUrl.substring(0, 5) === 'blob:') {
      this.dataURI = this.documentUrl;
    } else if (this.documentUrl) {
      this.loading = true;
      try {
        this.dataURI = await fetch(this.documentUrl)
          .then(response => response.blob())
          .then(blob => URL.createObjectURL(blob));
      } catch (e) {
        this.dataURI = '';
      }
      this.loading = false;
    }
    this.updateBackground();
  }

  updateBackground() {
    if (this.dataURI && this.$refs.thumb) {
      this.$refs.thumb.style.backgroundImage = 'url(' + this.dataURI + ')';
    }
  }

  openPreview(event: PointerEvent) {
    event.preventDefault();
    event.stopPropagation();
    event.cancelBubble = true;
    this.dialogVisible = true;
    return false;
  }
}
