
import { Component, Emit, Mixins, Vue, Watch } from 'vue-property-decorator';
import Field from '@/components/registration/Field.vue';
import { DATE_PATTERN, DATE_PATTERN_TO_SHOW } from '@/core/config/setup/product';
import { FieldValue } from '@/components/registration/models/field';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { stringifyFieldValue } from '@/components/registration/settings/helpers';
import { dateMask, TMask } from '@/components/registration/settings/mask';
import { FORM_ERRORS } from '@/components/registration/settings/rules';

dayjs.extend(customParseFormat);

class LmsFieldDateInputClass extends Field<FieldValue> {}

@Component({
  name: 'LmsDateInput'
})
export default class LmsFieldDateInput extends Mixins(LmsFieldDateInputClass) {
  date: FieldValue | null = this.dateInitial;
  mask: TMask = dateMask(this.date);

  get dateInitial(): string {
    if (typeof this.initial !== 'string') {
      return '';
    }

    const initial = stringifyFieldValue(this.initial);
    const isDate = dayjs(initial, DATE_PATTERN).isValid();

    return isDate ? dayjs(this.initial, DATE_PATTERN).format(DATE_PATTERN_TO_SHOW) : '';
  }

  onFocus() {
    this.focused = true;
    this.invalidate();
  }

  @Emit('blur')
  onBlur() {
    this.focused = false;
    const saved = this.date as string;
    if (saved.length !== 10) {
      this.error = FORM_ERRORS.FIELD_ERROR_DATE_FORMAT;
      return;
    }

    const dateFormat = dayjs(saved, DATE_PATTERN_TO_SHOW, true);
    if (dateFormat.isValid()) {
      this.updateValue(dateFormat.format(DATE_PATTERN));
    } else {
      this.error = FORM_ERRORS.FIELD_ERROR_DATE;
    }
  }

  @Watch('date')
  watchDate() {
    const date = stringifyFieldValue(this.date);
    this.mask = dateMask(date);
  }

  updateValue(nextVal: string) {
    Vue.nextTick().then(() => {
      this.value = nextVal;
      this.handleInput();
    });
  }
}
