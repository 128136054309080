import {
  emailFormat,
  idNumberFormat,
  numeric,
  phoneNumberFormat,
  required
} from '@/components/registration/settings/rules';
import { Input } from '@/components/registration/models/input';
import { iinMask, mobilePhoneMask } from '@/components/registration/settings/mask';
import { noSpacePattern } from '@/components/registration/settings/pattern';

interface PersonalDataConsentSetting {
  slug?: string;
  label: string;
}

interface PersonalDataConsent {
  name: string;
  setting: PersonalDataConsentSetting;
}

export const personalData: Input[] = [
  {
    name: 'username',
    setting: {
      tooltip: true,
      type: 'text',
      mask: iinMask,
      pattern: noSpacePattern
    },
    initial: '',
    autocomplete: 'username',
    rules: { required, numeric, idNumberFormat }
  },
  {
    name: 'email',
    setting: {
      tooltip: true,
      type: 'text',
      pattern: noSpacePattern
    },
    initial: '',
    autocomplete: 'email',
    rules: { required, emailFormat }
  },
  {
    name: 'mobilePhone',
    setting: {
      tooltip: true,
      type: 'text',
      mask: mobilePhoneMask,
      pattern: noSpacePattern
    },
    initial: '+7',
    autocomplete: 'tel',
    rules: { required, phoneNumberFormat }
  }
];

export const personalDataConsents: PersonalDataConsent[] = [
  {
    name: 'PERSONAL_DATA_PROCESSING',
    setting: {
      slug: 'doc-empty-consent-personal-data',
      label: 'CONSENTS.PERSONAL_DATA_PROCESSING'
    }
  },
  {
    name: 'CREDIT_REPORT',
    setting: {
      slug: 'doc-empty-consent-credit-report',
      label: 'CONSENTS.CREDIT_REPORT'
    }
  },
  {
    name: 'MICRO_CREDIT_INFO',
    setting: {
      slug: 'doc-empty-consent-credit-bureau-share',
      label: 'CONSENTS.MICRO_CREDIT_INFO'
    }
  },
  {
    name: 'INCOME_OUTCOME_PROCESSING',
    setting: {
      slug: 'doc-empty-consent-process-income',
      label: 'CONSENTS.INCOME_OUTCOME_PROCESSING'
    }
  },
  {
    name: 'NOT_FOREIGN_GOVERNMENT_OFFICIAL',
    setting: {
      label: 'CONSENTS.NOT_FOREIGN_GOVERNMENT_OFFICIAL'
    }
  }
];
