
import { Component, Mixins, Prop } from 'vue-property-decorator';
import Field from '@/components/registration/Field.vue';
import { RadioButton } from '@/components/registration/models/radio-button';

interface FieldRadioButtonsComponent {
  options: RadioButton[];
}

@Component({
  name: 'LmsRadioButtons'
})
export default class FieldRadioButtons extends Mixins(Field) implements FieldRadioButtonsComponent {
  @Prop({ required: true })
  options!: RadioButton[];
}
