
import { Vue, Component, Prop } from 'vue-property-decorator';
import ScheduleGraphic from '@/components/schedule/ScheduleGraphic.vue';
import { ScheduleGraphicPayment } from '@/components/schedule/ScheduleGraphic.types';
import { SchedulePayment } from '@/api/models/product';

@Component({ components: { ScheduleGraphic } })
export default class PaymentsGraph extends Vue {
  graphicActive = false;

  @Prop({ required: true })
  private payments: SchedulePayment[];

  get paymentsGraphic(): ScheduleGraphicPayment[] {
    return this.payments.map(payment => ({
      number: payment.number,
      principal: payment.totalPrincipalAmount,
      paymentDate: payment.period.endDate,
      fees: payment.interestAmount,
      totalToPay: payment.amount
    }));
  }

  showGraphic(show: boolean): void {
    this.graphicActive = show;
  }
}
