
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'LmsDocumentImagePreviewPopup'
})
export default class DocumentImagePreviewPopup extends Vue {
  @Prop({ required: true }) private dataURI!: string;
  @Prop() dialogVisible!: boolean;
  @Prop() title!: string;
}
