
import { Component, Mixins } from 'vue-property-decorator';
import { MULTI_LOAN_ROUTES } from '@/router/routes_type';
import { SEND_PROGRESS } from '@/router/routes';
import { progress } from '@/store/modules/progress';
import IncomeUploadPage from '@/views/registration/IncomeUploadPage.vue';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';

@Component({
  name: 'MultiLoanIncomeInfoPage',
  computed: {
    MULTI_LOAN_INCOME_ROUTE() {
      return MULTI_LOAN_ROUTES.INCOME_PAGE;
    }
  },
  components: { IncomeUploadPage }
})
export default class MultiLoanIncomeInfoPage extends Mixins(CheckRoutingMixin) {
  loading = false;

  mounted() {
    progress.set('65');
  }

  async submitForm() {
    this.loading = true;
    try {
      await this.redirect(MULTI_LOAN_ROUTES.SUMMARY_PAGE, { SEND_PROGRESS: SEND_PROGRESS }, true);
    } catch (error) {
      // linter says error for empty one
    }
    this.loading = false;
  }
}
