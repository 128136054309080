
import { Vue, Component, Emit, Ref } from 'vue-property-decorator';
import LmsForm from '@/components/registration/Form.vue';
import LmsInput from '@/components/registration/FieldInput.vue';
import LmsCheckboxGroup from '@/components/registration/FieldCheckBoxGroup.vue';
import { CompanyConsents, VerificationPopupDataInterface } from '@/components/registration/models/field';
import { required, numeric, allChecked, FORM_ERRORS } from '@/components/registration/settings/rules';
import { personalDataConsents } from '@/views/registration/RegistrationPersonalData';
import { InputSetting } from '@/components/registration/models/input';
import { phoneVerificationMask } from '@/components/registration/settings/mask';
import { LmsFormState } from '@/components/registration/models/form';
import LmsModal from '@/components/layout/modal.vue';
import { VERIFICATION_CONFIRM_STATUS, VERIFICATION_REPEAT_STATUS } from '@/api/models/verification';
import { RegistrationService } from '@/services/registration';

@Component({
  components: {
    LmsModal,
    LmsForm,
    LmsInput,
    LmsCheckboxGroup
  }
})
export default class ApplicationClientDataVerificationPopup extends Vue {
  @Ref('verificationForm') form: LmsFormState;

  codeRules = { required, numeric };
  codeSetting: InputSetting = {
    tooltip: true,
    placeholder: false,
    title: true,
    mask: phoneVerificationMask
  };
  consentsRules = { allChecked };
  consents = personalDataConsents;
  countDownTime = 0;
  countDownTimer = 0;
  loading = false;

  get hiddenPhoneNumber() {
    return RegistrationService.getHiddenIdentity;
  }

  mounted() {
    this.startTimer();
  }

  async repeatVerification() {
    if (this.countDownTime || !RegistrationService.verification) {
      return;
    }

    try {
      await RegistrationService.verification.repeat();

      await this.startTimer();
      this.$notify.success(this.$t('NOTIFICATION.SMS_CODE.TITLE').toString());
    } catch (e) {
      if (e.status === VERIFICATION_REPEAT_STATUS.INVALID_VERIFICATION_SUBMISSION) {
        return this.resetVerification();
      }
    }
  }

  async startTimer() {
    if (!RegistrationService.verification) {
      return;
    }
    const throttleTime = await RegistrationService.verification.getTimer();
    const now = new Date();
    now.setSeconds(now.getSeconds() + throttleTime);
    clearTimeout(this.countDownTimer);
    this.startTick(now);
  }

  startTick(date: Date) {
    const difference = date.getTime() - new Date().getTime();
    if (difference > 0) {
      this.countDownTime = Math.floor(difference / 1000);
      this.countDownTimer = setTimeout(() => {
        this.startTick(date);
      }, 1000);
    } else {
      this.countDownTime = 0;
    }
  }

  async verify({ code, consents }: VerificationPopupDataInterface) {
    if (!RegistrationService.verification) {
      return;
    }
    this.loading = true;
    try {
      await RegistrationService.verification.confirm(code);

      this.confirm(consents);
    } catch ({ data, status }) {
      switch (status) {
        case VERIFICATION_CONFIRM_STATUS.INVALID_VERIFICATION_CODE:
          this.form.setErrors([
            {
              parameters: {},
              propertyPath: 'code',
              title: FORM_ERRORS.FIELD_ERROR_WRONG_VERIFICATION_CODE
            }
          ]);
          break;
        case VERIFICATION_CONFIRM_STATUS.IS_ALREADY_CONFIRMED_OR_EXPIRED:
          await this.resetVerification();
          break;
        default:
          this.form.setErrors(data.violations);
      }
      this.loading = false;
    }
  }

  @Emit()
  close(): string {
    return '';
  }

  async resetVerification() {
    if (!RegistrationService.verification) {
      return;
    }
    await RegistrationService.verification.repeat();
    await this.startTimer();
    this.$notify.success(this.$t('NOTIFICATION.SMS_CODE.TITLE').toString());
  }

  @Emit()
  confirm(consents: CompanyConsents) {
    return consents;
  }
}
