
import { Component, Vue } from 'vue-property-decorator';
import ApplicationPage from '@/components/registration/ApplicationPage.vue';
import { progress } from '@/store/modules/progress';
import { MULTI_LOAN_ROUTES } from '@/router/routes_type';

const PARTNER_LINK = 'https://sunfinance.go2cloud.org/aff_c?offer_id=121&aff_id=1399';

@Component({
  name: 'RegistrationApplicationRejectPage',
  components: { ApplicationPage }
})
export default class RegistrationApplicationRejectPage extends Vue {
  page = { name: MULTI_LOAN_ROUTES.CALCULATOR };

  mounted() {
    progress.set('100');
  }

  get partnerLink(): string {
    return PARTNER_LINK;
  }
}
