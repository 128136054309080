
import { Component, Vue } from 'vue-property-decorator';
import SummaryForm from '@/components/registration/SummaryForm.vue';
import ApplicationPage from '@/components/registration/ApplicationPage.vue';
import { progress } from '@/store/modules/progress';
import { REGISTRATION_ROUTES } from '@/router/routes_type';

@Component({
  name: 'RegistrationSummaryPage',
  components: { SummaryForm, ApplicationPage }
})
export default class RegistrationSummaryPage extends Vue {
  errorPage = REGISTRATION_ROUTES.CALCULATOR;

  mounted() {
    progress.set('80');
  }
}
