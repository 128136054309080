import { TBankCardVerificationValue } from '@/services/bankCard';
import { FORM_ERRORS } from '@/components/registration/settings/rules';

export type CardAttachmentFieldValue = TBankCardVerificationValue | null;

export const bankCardVerified = (value: CardAttachmentFieldValue) => {
  const hasValue = typeof value === 'object' && !!Object.keys(value || {}).length;
  switch (true) {
    case !hasValue:
      return FORM_ERRORS.FIELD_ERROR_BANK_CARD_NOT_VERIFIED;
  }
};
