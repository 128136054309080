
import { Component, Mixins, Watch } from 'vue-property-decorator';
import VueMarkdown from 'vue-markdown';
import Field from '@/components/registration/Field.vue';
import LmsForm from '@/components/registration/Form.vue';
import LmsInput from '@/components/registration/FieldInput.vue';
import LmsBankCardPreview from '@/components/registration/disbursementType/bankCardAttachment/BankCardPreview.vue';

import LmsBankCardConfirmAmount from '@/components/registration/disbursementType/bankCardAttachment/BankCardConfirmAmount.vue';
import { CardAttachmentFieldValue } from '@/components/registration/disbursementType/bankCardAttachment/BankCardAttachment.values';
import BankCardVerificationHandler from '@/components/verification/BankCardVerificationHandler.vue';
import { TBankCardVerificationState } from '@/services/bankCard';
import { VERIFICATION_STATE } from '@/api/models/verification';

class CardAttachmentField extends Field<CardAttachmentFieldValue> {}

@Component({
  name: 'LmsBankCardAttachment',
  components: { LmsBankCardConfirmAmount, LmsBankCardPreview, LmsInput, LmsForm, VueMarkdown }
})
export default class BankCardAttachment extends Mixins(CardAttachmentField, BankCardVerificationHandler) {
  get showStartVerification(): boolean {
    return this.noVerification || this.verificationLoading || Boolean(this.verificationError);
  }

  get showLinkedCard(): boolean {
    return !!this.value || (!!this.verificationState && !!this.cardMask);
  }

  get initFakeVerification(): TBankCardVerificationState | null {
    if (this.value) {
      return {
        state: VERIFICATION_STATE.CONFIRMED,
        receiptStamp: {
          cardHolder: this.value.cardHolder,
          cardId: this.value.cardId,
          panMasked: this.value.panMasked,
          userId: this.value.userId,
          userLogin: this.value.userLogin
        }
      } as TBankCardVerificationState;
    }

    return null;
  }

  get showConfirmAmount(): boolean {
    return !!this.verificationState && this.notConfirmed && this.notExpired && this.isCheckRequired;
  }

  get cardMask() {
    return this.verificationCard?.panMasked;
  }

  get noVerification(): boolean {
    return !this.verificationState;
  }

  get isConfirmed(): boolean {
    return !!this.verificationState?.isConfirmed;
  }

  get notConfirmed(): boolean {
    return !this.isConfirmed;
  }

  get notExpired(): boolean {
    return !this.verificationState?.isExpired;
  }

  @Watch('verificationError')
  watchVerificationError() {
    this.error = this.getVerificationError();
  }

  @Watch('verification')
  watchVerification() {
    if (this.verificationCard && this.isConfirmed) {
      const { cardId, cardHolder, panMasked, userId, userLogin } = this.verificationCard;
      this.value = { cardId, cardHolder, panMasked, userId, userLogin };
    } else {
      this.value = null;
    }
  }

  handleUnlink() {
    this.value = null;
    this.unlinkVerification();
  }
}
