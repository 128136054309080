
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Document, DOCUMENT_TYPES, MediaRequestDto } from '@/api/models/document';
import LmsDocumentImageUpload from '@/components/DocumentImageInput/DocumentImageUpload.vue';
import LmsDocumentImagePreview from '@/components/DocumentImageInput/DocumentImagePreview.vue';

@Component({
  name: 'LmsDocumentImageInput',
  components: { LmsDocumentImagePreview, LmsDocumentImageUpload }
})
export default class DocumentImageInputComponent extends Vue {
  @Prop() private placeholder: string;
  @Prop({ required: true }) private documentType!: DOCUMENT_TYPES;
  @Prop({ required: true }) private document!: Document;
  @Prop({ required: true }) private progress: number;

  @Emit('remove')
  handleRemove() {
    return;
  }

  @Emit('upload')
  handleUpload(media: MediaRequestDto) {
    return media;
  }
}
