
import { Component, Mixins, Watch } from 'vue-property-decorator';
import ApplicationPage from '@/components/registration/ApplicationPage.vue';
import { progress } from '@/store/modules/progress';
import { SEND_PROGRESS } from '@/router/routes';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';
import { REGISTRATION_ROUTES } from '@/router/routes_type';
import { applications } from '@/store/modules/application';

const POOLING_INTERVAL = 5000;
const VALID_NEXT_ROUTES: string[] = [
  REGISTRATION_ROUTES.APPLICATION_REJECT_PAGE,
  REGISTRATION_ROUTES.AGREEMENT_ONLINE_SIGNING_PAGE,
  REGISTRATION_ROUTES.AGREEMENT_SIGNING_PAGE
];

@Component({
  name: 'RegistrationManualVerificationLoadingPage',
  components: { ApplicationPage }
})
export default class RegistrationManualVerificationLoadingPage extends Mixins(CheckRoutingMixin) {
  private redirectTimeout: number;

  @Watch('progress', { immediate: true })
  setProgress() {
    progress.set('90');
  }

  created() {
    this.checkApprovedApplication();
  }

  checkApprovedApplication() {
    this.redirectTimeout = setTimeout(async () => {
      if (!(await applications.initGetApplications())) {
        return;
      }

      if (applications.lastApplication) {
        const route = await this.checkRouteBeforeProgress(REGISTRATION_ROUTES.MANUAL_VERIFICATION_PAGE);
        if (route && VALID_NEXT_ROUTES.includes(route)) {
          return this.redirect(route, { SEND_PROGRESS: SEND_PROGRESS });
        }
      }

      return this.checkApprovedApplication();
    }, POOLING_INTERVAL);
  }

  beforeDestroy() {
    clearTimeout(this.redirectTimeout);
  }
}
