
import { Component, Mixins } from 'vue-property-decorator';
import ApplicationPage from '@/components/registration/ApplicationPage.vue';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';

import DisbursementType from '@/components/registration/DisbursementType.vue';
import { REGISTRATION_ROUTES } from '@/router/routes_type';
import { SEND_PROGRESS } from '@/router/routes';
import { progress } from '@/store/modules/progress';

@Component({
  name: 'RegistrationDisbursementTypePage',
  components: {
    ApplicationPage,
    DisbursementType
  }
})
export default class RegistrationDisbursementTypePage extends Mixins(CheckRoutingMixin) {
  page = REGISTRATION_ROUTES.DISBURSEMENT_TYPE_PAGE;

  mounted() {
    progress.set('55');
  }

  async submit() {
    const route = await this.checkRouteBeforeProgress(this.page);
    if (route) await this.redirect(route, { SEND_PROGRESS: SEND_PROGRESS });
  }
}
