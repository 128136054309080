
import { Component, Ref, Vue } from 'vue-property-decorator';
import ProgressBar from '@/components/registration/ProgressBar.vue';
import ApplicationPopup from '@/components/registration/ApplicationClientDataVerificationPopup.vue';
import LmsForm from '@/components/registration/Form.vue';
import LmsInput from '@/components/registration/FieldInput.vue';
import ApplicationPage from '@/components/registration/ApplicationPage.vue';
import { personalData } from './RegistrationPersonalData';
import { PersonalDataFormPayload } from '@/api/models/client';
import { LmsFormState } from '@/components/registration/models/form';
import { progress } from '@/store/modules/progress';
import { iovation } from '@/store/modules/iovation';
import { RegistrationService } from '@/services/registration';
import { SeonService } from '@/views/services/SeonService';
import { loader } from '@/utils/loader';
import router from '@/router';
import { REGISTRATION_ROUTES } from '@/router/routes_type';

@Component({
  components: {
    ProgressBar,
    LmsForm,
    LmsInput,
    ApplicationPopup,
    ApplicationPage
  },
  metaInfo() {
    return {
      script: [
        { src: 'https://cdn.seondf.com/js/v6/agent.umd.js', defer: true },
        { src: '/iovation.js', defer: true }
      ]
    };
  },
  beforeRouteLeave(to, from, next) {
    const { value: iovationHash } = document.getElementById('ioBB') as HTMLInputElement;

    if (iovationHash) {
      iovation.set(iovationHash);
    }

    next();
  }
})
export default class RegistrationPersonalDataPage extends Vue {
  @Ref('form') form!: LmsFormState;

  fieldset = personalData;

  loading = false;

  verificationPopup = false;

  mounted() {
    progress.set('10');
  }

  stopVerification() {
    this.verificationPopup = false;
  }

  initSeon() {
    SeonService.init();
  }

  async submit(payload: PersonalDataFormPayload) {
    this.loading = true;

    RegistrationService.data.username = payload.username;
    RegistrationService.data.email = payload.email;
    RegistrationService.data.mobilePhone = payload.mobilePhone;

    try {
      await RegistrationService.initVerification();
      this.verificationPopup = true;
    } catch (e) {
      console.error(e);
    }

    this.loading = false;
  }

  async submitVerification(consents: Record<string, boolean>) {
    try {
      await RegistrationService.createClient(consents);
      await Promise.allSettled([iovation.init(), SeonService.storeSession()]);
      await loader.show();
      await router.push({ name: REGISTRATION_ROUTES.VERIGRAM });
    } catch (e) {
      this.stopVerification();
      this.form.setErrors(e.data.violations);
    }
  }
}
