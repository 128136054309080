
import { Component, Mixins } from 'vue-property-decorator';
import {
  DOCUMENT_TYPES,
  ID_DOCUMENT_TYPES,
  ImageModel,
  MediaRequestDto,
  OTHER_DOCUMENT_TYPES
} from '@/api/models/document';
import LmsForm from '@/components/registration/Form.vue';
import LmsModal from '@/components/layout/modal.vue';
import { TranslateResult } from 'vue-i18n';
import { uploadDocument } from '@/api/document';
import { loader } from '@/utils/loader';
import LmsImageUploader from '@/components/DocumentImageInput/DocumentImageUpload.vue';
import LmsDocumentImageUploader from '@/components/DocumentImageInput/DocumentImageUpload.vue';
import LmsDocumentImageInput from '@/components/DocumentImageInput/DocumentImageInput.vue';
import { GRID_ROW_TYPE, GridRow } from '@/components/text/types';
import GridRowImages from '@/components/text/GridRowImages.vue';
import GridRowParagraph from '@/components/text/GridRowParagraph.vue';
import router from '@/router';
import { REGISTRATION_ROUTES } from '@/router/routes_type';
import { SEND_PROGRESS } from '@/router/routes';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';
import { progress } from '@/store/modules/progress';
import { DocumentService } from '@/services/document';
import { documents } from '@/store/modules/documents';
import { client } from '@/store/modules/client';

export type ProgressModel = Partial<Record<DOCUMENT_TYPES, number>>;
export type MediaModel = Partial<Record<DOCUMENT_TYPES, MediaRequestDto | null>>;

@Component({
  components: {
    GridRowParagraph,
    GridRowImages,
    LmsDocumentImageInput,
    LmsDocumentImageUploader,
    LmsImageUploader,
    LmsModal,
    LmsForm
  }
})
export default class RegistrationDocumentsPage extends Mixins(CheckRoutingMixin) {
  documents: ImageModel = this.selfieNecessary
    ? {
      [ID_DOCUMENT_TYPES.FRONT]: null,
      [ID_DOCUMENT_TYPES.BACK]: null,
      [OTHER_DOCUMENT_TYPES.SELFIE]: null
    }
    : {
      [ID_DOCUMENT_TYPES.FRONT]: null,
      [ID_DOCUMENT_TYPES.BACK]: null
    };

  progress: ProgressModel = {
    [ID_DOCUMENT_TYPES.FRONT]: 0,
    [ID_DOCUMENT_TYPES.BACK]: 0,
    [OTHER_DOCUMENT_TYPES.SELFIE]: 0
  };

  media: MediaModel = {
    [ID_DOCUMENT_TYPES.FRONT]: null,
    [ID_DOCUMENT_TYPES.BACK]: null,
    [OTHER_DOCUMENT_TYPES.SELFIE]: null
  };

  isTroubleshootingModalOpen = false;
  $t: (key: string) => TranslateResult;
  loading = false;

  async created() {
    await this.refreshDocuments();
  }

  mounted() {
    progress.set('65');
  }

  get ID_DOCUMENT_TYPES() {
    return ID_DOCUMENT_TYPES;
  }

  get OTHER_DOCUMENT_TYPES() {
    return OTHER_DOCUMENT_TYPES;
  }

  get selfieNecessary(): boolean {
    return !client.usernameVerificationConfirmed || !DocumentService.hasVerigramDocuments();
  }

  get submitDisabled() {
    for (const key in this.documents) {
      if (this.documents[key as DOCUMENT_TYPES] === null && this.media[key as DOCUMENT_TYPES] === null) {
        return true;
      }
    }

    return false;
  }

  isImages(row: GridRow) {
    return row.TYPE === GRID_ROW_TYPE.IMAGES;
  }

  isParagraph(row: GridRow) {
    return !row.TYPE || row.TYPE === GRID_ROW_TYPE.PARAGRAPH;
  }

  async refreshDocuments() {
    await documents.initDocuments();
    DocumentService.getPersonDocuments().forEach(document => {
      const documentType = document.type as DOCUMENT_TYPES;
      const doc = this.documents[documentType];
      const notRemoved = !document.removed;
      const isUpdated = !doc || new Date(doc.created.at) < new Date(document.created.at);

      if (notRemoved && isUpdated) {
        this.documents[documentType] = document;
        this.progress[documentType] = 100;
      }
    });
  }

  async handleRemove(type: DOCUMENT_TYPES) {
    this.documents[type] = null;
    this.media[type] = null;
    this.progress[type] = 0;
  }

  async submit() {
    if (this.submitDisabled) {
      return;
    }
    const route = await this.checkRouteBeforeProgress(REGISTRATION_ROUTES.DOCUMENTS_PAGE);
    if (route) return this.redirect(route);

    this.loading = true;
    for (const key in this.media) {
      const media = this.media[key as DOCUMENT_TYPES];
      if (media) {
        await this.upload(media);
      }
    }

    try {
      await loader.show();
      await router.push({ name: REGISTRATION_ROUTES.CALCULATOR, params: { SEND_PROGRESS: SEND_PROGRESS } });
    } finally {
      this.loading = false;
    }
  }

  async handleUpload(media: MediaRequestDto) {
    this.media[media.type] = media;
  }

  async upload(media: MediaRequestDto) {
    await uploadDocument(media, {
      timeout: 340000,
      onUploadProgress: progressEvent => {
        this.progress[media.type] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      }
    });
  }
}
