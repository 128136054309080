
import { Component, Mixins } from 'vue-property-decorator';
import LmsCalculator from '@/components/calculator/Calculator.vue';
import { MULTI_LOAN_ROUTES } from '@/router/routes_type';
import { SEND_PROGRESS } from '@/router/routes';
import ProgressBar from '@/components/registration/ProgressBar.vue';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';

@Component({
  name: 'MultiLoanCalculatorPage',
  components: { ProgressBar, LmsCalculator }
})
export default class MultiLoanCalculatorPage extends Mixins(CheckRoutingMixin) {
  page = MULTI_LOAN_ROUTES.CALCULATOR;
  calculatorLoading = false;

  load(loading: boolean) {
    this.calculatorLoading = loading;
  }

  async submit() {
    await this.redirect(MULTI_LOAN_ROUTES.DISBURSEMENT_TYPE_PAGE, { SEND_PROGRESS: SEND_PROGRESS }, true);
  }
}
