
import { Mixins, Prop } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';
import LmsSelect from '@/components/registration/FieldSelect.vue';
import LmsCheckbox from '@/components/registration/FieldCheckBox.vue';
import LmsForm from '@/components/registration/Form.vue';
import LmsInput from '@/components/registration/FieldInput.vue';
import { hasLetter, required } from '@/components/registration/settings/rules';
import { alphaNumericMask, houseApartmentNumberMask } from '@/components/registration/settings/mask';
import { AddressesMap } from '@/api/models/client';
import { ContentService } from '@/services/content';
import { SECTIONS } from '@/store/modules/models/content';
import { set } from 'lodash-es';
import { REGISTRATION_ROUTES } from '@/router/routes_type';
import { addStep, buildUiStepState } from '@/api/progress';
import { UI_CLIENT_FLOW_STEP_NAME } from '@/api/models/progress';
import { AdditionalInfoSection } from '@/views/registration/AdditionalInfo/ApplicationAdditionalInfo.config';
import { LmsFormState } from '@/components/registration/models/form';
import { ActionFailedViolation } from '@/utils/request';
import { client } from '@/store/modules/client';

@Component({
  name: 'AddressData',
  components: { LmsInput, LmsForm, LmsCheckbox, LmsSelect },
  methods: { hasLetter, alphaNumericMask, required }
})
export default class AddressData extends Mixins(CheckRoutingMixin) {
  @Prop({ default: true })
  loading: boolean;

  addressData: AddressesMap = client.addresses;

  get living() {
    return this.addressData.living;
  }

  get declared() {
    return this.addressData.declared;
  }

  get matchesDeclaredAddress() {
    return this.addressData.matchesDeclaredAddress;
  }

  get regionOptions() {
    return () => ContentService.sortedOptions(SECTIONS.REGION);
  }

  get houseApartmentNumberMask(): string {
    return houseApartmentNumberMask;
  }

  setValue(field: string, value: any) {
    set(this.addressData, field, value);
  }

  async submitForm() {
    this.$emit('update:loading', true);

    const route = await this.checkRouteBeforeProgress(REGISTRATION_ROUTES.ADDITIONAL_INFO);
    if (route) return this.redirect(route);

    try {
      await client.updateAddressData(this.addressData);
      await addStep(buildUiStepState(UI_CLIENT_FLOW_STEP_NAME.ADDRESS));
      setTimeout(() => this.$emit('submit'), 1000);
    } catch (response) {
      const currentForm = this.$refs[AdditionalInfoSection.ADDRESS] as LmsFormState;
      const violations: ActionFailedViolation[] = response.data.violations ?? undefined;

      currentForm.setErrors(violations);
      this.$emit('update:loading', false);
    }
  }
}
