
import dayjs from 'dayjs';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { DATE_PATTERN_TO_SHOW } from '@/core/config/setup/product';
import PaymentsGraph from '@/components/schedule/PaymentsGraph.vue';
import { ScheduleResponse } from '@/api/models/product';

@Component({ components: { PaymentsGraph } })
export default class Schedule extends Vue {
  @Prop({ required: true })
  schedule: ScheduleResponse | null = null;

  @Prop({ required: true })
  scheduleLoading = true;

  get paymentDate() {
    const paymentDate = this.schedule?.result.instalments[0].period.endDate;
    return paymentDate && dayjs(paymentDate).format(DATE_PATTERN_TO_SHOW);
  }

  get averagePayment(): string {
    return this.schedule?.result.summary.annuityPayment ?? '';
  }
}
