
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GridImages } from './types';

@Component({
  name: 'GridRowImages'
})
export default class LmsGridRowImages extends Vue {
  @Prop() private cells: GridImages;
}
