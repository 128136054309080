
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { ROUTE } from '@/router/routes_type';
import { set } from 'lodash-es';
import { Document, DocumentMetadata, OTHER_DOCUMENT_TYPES, UploadSource } from '@/api/models/document';
import { FieldRule, FORM_ERRORS } from '@/components/registration/settings/rules';
import { uploadDocument } from '@/api/document';
import { AdditionalInfoSection } from '@/views/registration/AdditionalInfo/ApplicationAdditionalInfo.config';
import { LmsFormState } from '@/components/registration/models/form';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';
import { client } from '@/store/modules/client';
import { FinancesData } from '@/api/models/client';
import { AxiosResponse } from 'axios';

@Component
export default class FinancesMixin extends Mixins(CheckRoutingMixin) {
  @Prop({ default: true })
  loading: boolean;

  financesData: FinancesData = client.financesData;
  files: UploadSource[] = [];

  afterStartDate: FieldRule = value => {
    if (!this.financesData.bankStatement.startDate || !value) {
      return '';
    }

    const startDate = new Date(this.financesData.bankStatement.startDate);
    const endDate = new Date(value.toString());

    return startDate < endDate ? '' : FORM_ERRORS.BANK_STATEMENT_END_DATE_BEFORE_START_DATE;
  };

  setValue(field: string, value: any) {
    set(this.financesData, field, value);
  }

  upload(fileList: UploadSource[]) {
    this.files = fileList;
  }

  async updateFinancesData(
    checkRoute: ROUTE,
    metadata: DocumentMetadata = {}
  ): Promise<void | AxiosResponse<Document>[]> {
    const route = await this.checkRouteBeforeProgress(checkRoute);
    if (route) return this.redirect(route);

    try {
      await client.updateFinancesData(this.financesData);
      const uploadPromises = this.files.map((source: UploadSource) => {
        return uploadDocument({
          type: OTHER_DOCUMENT_TYPES.BANK_STATEMENT,
          file: source.raw,
          metadata: metadata
        });
      });

      return await Promise.all(uploadPromises);
    } catch (response) {
      const currentForm = this.$refs[AdditionalInfoSection.FINANCES] as LmsFormState;

      if (response.status === 400 && response.data.detail.includes('fileInfo.mimeType')) {
        currentForm.setErrors(undefined, this.$t(`FORM.${FORM_ERRORS.FIELD_ERROR_FILE_TYPE}`).toString());
      } else {
        currentForm.setErrors(response.data?.violations ?? undefined);
      }

      throw new Error('Failed to update finance data');
    }
  }
}
