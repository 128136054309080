
import { Component, Mixins, Watch } from 'vue-property-decorator';
import ApplicationPage from '@/components/registration/ApplicationPage.vue';
import FormCollapse from '@/components/registration/FormCollapse.vue';
import { AdditionalInfoSection, availableSectionsByStep } from './ApplicationAdditionalInfo.config';
import { progress } from '@/store/modules/progress';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';
import Finances from '@/views/registration/AdditionalInfo/Finances.vue';
import PersonalData from './PersonalData.vue';
import AdditionalInfo from '@/views/registration/AdditionalInfo/AdditionalInfo.vue';
import { REGISTRATION_ROUTES } from '@/router/routes_type';
import AddressData from '@/views/registration/AdditionalInfo/AddressData.vue';
import { client } from '@/store/modules/client';
import { DocumentService } from '@/services/document';
import { documents } from '@/store/modules/documents';

@Component({
  name: 'RegistrationAdditionalInfoPage',
  computed: {
    AdditionalInfoSection() {
      return AdditionalInfoSection;
    }
  },
  components: {
    AddressData,
    AdditionalInfo,
    Finances,
    PersonalData,
    ApplicationPage,
    FormCollapse
  }
})
export default class RegistrationAdditionalInfoPage extends Mixins(CheckRoutingMixin) {
  activeSection: null | AdditionalInfoSection = null;
  availableSections: AdditionalInfoSection[] = [];
  availableSectionsConfig = availableSectionsByStep;

  loading = true;

  created() {
    client.initProfile();
    documents.initDocuments();
    this.activeSection = this.getActiveSection();
  }

  async mounted() {
    progress.set('35');
    this.availableSections = this.getAvailableSections();
    this.loading = false;
  }

  async submit(): Promise<void> {
    await client.initProfile({ force: true });
    await documents.initDocuments();
    this.activeSection = this.getActiveSection();

    if (this.activeSection) {
      this.availableSections = this.getAvailableSections();
      this.loading = false;
      return;
    }

    const route = await this.checkRouteBeforeProgress(REGISTRATION_ROUTES.ADDITIONAL_INFO);
    if (route) return this.redirect(route);
  }

  getActiveSection(): AdditionalInfoSection | null {
    if (!client.personalDataFilled) {
      return AdditionalInfoSection.PERSONAL_DATA;
    }

    if (!client.addressesFilled) {
      return AdditionalInfoSection.ADDRESS;
    }

    if (!client.financesDataFilled || !DocumentService.hasBankStatement()) {
      return AdditionalInfoSection.FINANCES;
    }

    if (!client.familyAdditionalInfoFilled) {
      return AdditionalInfoSection.ADDITIONAL_INFO;
    }

    return null;
  }

  get fieldset(): AdditionalInfoSection[] {
    return [
      AdditionalInfoSection.PERSONAL_DATA,
      AdditionalInfoSection.ADDRESS,
      AdditionalInfoSection.FINANCES,
      AdditionalInfoSection.ADDITIONAL_INFO
    ];
  }

  getAvailableSections(): AdditionalInfoSection[] {
    if (!this.activeSection) {
      return [];
    }

    return this.availableSectionsConfig[this.activeSection] || [];
  }

  switchSections(name: AdditionalInfoSection) {
    if (name) {
      this.activeSection = name;
    }
  }

  @Watch('activeSection')
  watchActiveSection(newValue: string, oldValue: string) {
    const oldSection = document.getElementById(oldValue);
    const oldCollapse = oldSection?.querySelector('.el-collapse-item__wrap');
    const newSection = document.getElementById(newValue);
    let newSectionTop = newSection?.getBoundingClientRect().top || 0;
    if (oldCollapse) {
      const oldCollapseRect = oldCollapse.getBoundingClientRect();
      const visibleCollapseTop = oldCollapseRect.top;
      if (visibleCollapseTop < newSectionTop) {
        newSectionTop -= oldCollapseRect.height;
      }
    }
    window.scrollTo(0, newSectionTop + window.scrollY);
  }
}
