
import { Component, Prop, Vue } from 'vue-property-decorator';
import LmsInput from '@/components/registration/FieldInput.vue';
import LmsForm from '@/components/registration/Form.vue';
import { InputSetting } from '@/components/registration/models/input';
import { numeric, required } from '@/components/registration/settings/rules';
import { noSpacePattern } from '@/components/registration/settings/pattern';

@Component({
  name: 'LmsBankCardConfirmAmount',
  components: { LmsInput, LmsForm }
})
export default class BankCardAttachment extends Vue {
  @Prop({ required: true }) private check!: (totalAmount: string) => Promise<void>;

  fieldName = 'testAmount';

  loading = false;

  error = '';

  amountFieldSettings: InputSetting = {
    tooltip: true,
    type: 'text',
    pattern: noSpacePattern,
    mask: '######'
  };

  amountFieldRules = { required, numeric };

  handleFocus() {
    this.error = '';
  }

  async checkAmount({ [this.fieldName]: testAmount }: Record<string, string>) {
    this.loading = true;
    try {
      await this.check(testAmount);
    } finally {
      this.loading = false;
    }
  }
}
