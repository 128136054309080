import { saveSeon } from '@/api/risks-report';

export interface Seon {
  init: () => void;
  getSession: () => Promise<string>;
}

export const SeonService = new (class {
  private inited = false;

  async getSeonSessionHash(): Promise<string> {
    return await window.seon.getSession();
  }

  init(): void {
    if (this.inited) {
      return;
    }

    return window.seon.init();
  }

  async storeSession() {
    try {
      const session = await this.getSeonSessionHash();
      await saveSeon({ session });
    } catch (e) {
      console.error(e);
    }
  }
})();
