import { debounce, DebounceSettings } from 'lodash-es';

export const Debounce = (delay: number, settings?: DebounceSettings) => {
  // eslint-disable-next-line
  return (target: any, prop: string) => ({
    configurable: true,
    enumerable: false,
    value: debounce(target[prop], delay, settings)
  });
};
