
import { Component, Vue } from 'vue-property-decorator';
import ApplicationPage from '@/components/registration/ApplicationPage.vue';
import { progress } from '@/store/modules/progress';
import SummaryForm from '@/components/registration/SummaryForm.vue';
import { MULTI_LOAN_ROUTES } from '@/router/routes_type';

@Component({
  name: 'MultiLoanSummaryPage',
  components: {
    SummaryForm,
    ApplicationPage
  }
})
export default class MultiLoanSummaryPage extends Vue {
  errorPage = MULTI_LOAN_ROUTES.CALCULATOR;

  mounted() {
    progress.set('80');
  }
}
