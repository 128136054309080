
import { Mixins, Prop } from 'vue-property-decorator';
import { Component } from 'vue-property-decorator';
import { required, phoneNumberFormat, clientPhoneNumber } from '@/components/registration/settings/rules';
import { mobilePhoneMask } from '@/components/registration/settings/mask';
import LmsInput from '@/components/registration/FieldInput.vue';
import LmsSelect from '@/components/registration/FieldSelect.vue';
import LmsForm from '@/components/registration/Form.vue';
import { ContentService } from '@/services/content';
import { SECTIONS } from '@/store/modules/models/content';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';
import { REGISTRATION_ROUTES } from '@/router/routes_type';
import { addStep, buildUiStepState } from '@/api/progress';
import { UI_CLIENT_FLOW_STEP_NAME } from '@/api/models/progress';
import { AdditionalInfoSection } from '@/views/registration/AdditionalInfo/ApplicationAdditionalInfo.config';
import { LmsFormState } from '@/components/registration/models/form';
import { ActionFailedViolation } from '@/utils/request';
import { set } from 'lodash-es';
import { AdditionalInfo as AdditionalInfoModel } from '@/api/models/client';
import { noSpacePattern } from '@/components/registration/settings/pattern';
import { client } from '@/store/modules/client';

@Component({
  name: 'AdditionalInfo',
  components: {
    LmsForm,
    LmsInput,
    LmsSelect
  },
  methods: {
    noSpacePattern,
    required,
    clientPhoneNumber,
    phoneNumberFormat
  }
})
export default class AdditionalInfo extends Mixins(CheckRoutingMixin) {
  @Prop({ default: true })
  loading: boolean;

  additionalInfo: AdditionalInfoModel = client.familyAdditionalInfo;

  get materialStatusOptions() {
    return () => ContentService.sortedOptions(SECTIONS.MARITAL_STATUS);
  }

  get childrenOptions() {
    return () => ContentService.sortedOptions(SECTIONS.CHILDREN_COUNT);
  }

  get mobilePhoneMask() {
    return mobilePhoneMask;
  }

  setValue(field: string, value: any) {
    set(this.additionalInfo, field, value);
  }

  async submitForm() {
    this.$emit('update:loading', true);

    const route = await this.checkRouteBeforeProgress(REGISTRATION_ROUTES.ADDITIONAL_INFO);
    if (route) return this.redirect(route);

    try {
      await client.updateFamilyAdditionalInfo(this.additionalInfo);
      await addStep(buildUiStepState(UI_CLIENT_FLOW_STEP_NAME.ADDITIONAL_INFO));
      this.$emit('submit');
    } catch (response) {
      const currentForm = this.$refs[AdditionalInfoSection.ADDITIONAL_INFO] as LmsFormState;
      const violations: ActionFailedViolation[] = response.data?.violations ?? undefined;

      currentForm.setErrors(violations);
      this.$emit('update:loading', false);
    }
  }
}
