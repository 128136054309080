export const MAX_WIDTH = 1600;
export const MAX_HEIGHT = 1600;

export enum UPLOAD_STATUS {
  INIT,
  STARTED,
  COMPLETED,
  SUCCESS,
  ERROR,
  READY
}
