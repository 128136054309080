
import { Component } from 'vue-property-decorator';
import LmsCalculator from '@/components/calculator/Calculator.vue';
import ProgressBar from '@/components/registration/ProgressBar.vue';
import { progress } from '@/store/modules/progress';
import { REGISTRATION_ROUTES } from '@/router/routes_type';
import { SEND_PROGRESS } from '@/router/routes';
import { Mixins } from 'vue-property-decorator';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';

@Component({ components: { LmsCalculator, ProgressBar } })
export default class RegistrationCalculatorPage extends Mixins(CheckRoutingMixin) {
  calculatorLoading = false;

  page = REGISTRATION_ROUTES.CALCULATOR;

  mounted() {
    progress.set('75');
  }

  load(loading: boolean) {
    this.calculatorLoading = loading;
  }

  async submit() {
    await this.redirect(REGISTRATION_ROUTES.SUMMARY_PAGE, { SEND_PROGRESS: SEND_PROGRESS });
  }
}
