
import { Component, Mixins } from 'vue-property-decorator';
import { REGISTRATION_ROUTES } from '@/router/routes_type';
import { progress } from '@/store/modules/progress';
import { transitToAdditionalAction } from '@/api/application';
import { applications } from '@/store/modules/application';
import { AdditionalActionTransition } from '@/api/models/application';
import { isArray } from 'lodash-es';
import { SEND_PROGRESS } from '@/router/routes';
import IncomeUploadPage from '@/views/registration/IncomeUploadPage.vue';
import { AxiosResponse } from 'axios';
import { Document } from '@/api/models/document';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';

@Component({
  name: 'RegistrationIncomeReuploadPage',
  computed: {
    REGISTRATION_REUPLOAD_INCOME_ROUTE() {
      return REGISTRATION_ROUTES.INCOME_REUPLOAD_PAGE;
    }
  },
  components: { IncomeUploadPage }
})
export default class RegistrationIncomeReuploadPage extends Mixins(CheckRoutingMixin) {
  loading = false;

  mounted() {
    progress.set('90');
  }

  async submitForm(documents: AxiosResponse<Document>[]) {
    const applicationId = applications.lastApplication?.id;
    if (!applicationId) {
      return;
    }

    try {
      const payload = isArray(documents)
        ? documents.map(d => {
          return { documentId: d.data.id };
        })
        : {};

      await transitToAdditionalAction(applicationId, {
        transition: AdditionalActionTransition.REUPLOAD_BANK_STATEMENT,
        payload: payload
      });

      await this.redirect(REGISTRATION_ROUTES.DECISION_PAGE, { SEND_PROGRESS: SEND_PROGRESS }, true);
    } catch (error) {
      // linter says error for empty one
    }
    this.loading = false;
  }
}
