
import { Component, Mixins, Prop } from 'vue-property-decorator';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';
import { client } from '@/store/modules/client';
import { PRODUCT } from '@/core/config/setup/product';
import { REGISTRATION_ROUTES, ROUTE } from '@/router/routes_type';
import { ApplicationPayload } from '@/api/models/application';
import { applications } from '@/store/modules/application';
import { marketingMetrics } from '@/utils/marketing';
import { SEND_PROGRESS } from '@/router/routes';
import { nextTryDateTime } from '@/api/models/verification';
import PaymentsGraph from '@/components/schedule/PaymentsGraph.vue';
import { clearCalculation, clearRoute, retrieveCalculation } from '@/router/router';
import { errors } from '@/store/modules/errors';
import { schedule } from '@/store/modules/schedule';
import { calculator } from '@/store/modules/calculator';

@Component({
  name: 'SummaryForm',
  components: { PaymentsGraph }
})
export default class SummaryForm extends Mixins(CheckRoutingMixin) {
  @Prop({ required: true })
  previousPage: ROUTE;

  loading = true;

  async created() {
    const calculation = retrieveCalculation();
    if (!calculation) {
      this.loading = false;
      this.decline();

      return;
    }

    calculator.chooseProduct(calculation.product.revision.alias as PRODUCT);
    calculator.setTerm(calculation.loan.term);
    calculator.setAmount(calculation.loan.amount);

    try {
      await schedule.loadSchedules();
    } catch (e) {
      this.decline();
      throw e;
    } finally {
      this.loading = false;
    }
  }

  get loanAmount() {
    return calculator.amount ?? 0;
  }

  get term() {
    return calculator.term ?? 0;
  }

  get averagePayment(): string {
    return schedule.averagePayment;
  }

  get payments() {
    return schedule.currentSchedule?.result.instalments ?? [];
  }

  decline() {
    clearRoute();
    clearCalculation();
    this.redirect(this.previousPage, {});
  }

  async createApplication() {
    const calculation = retrieveCalculation();

    if (!calculation || !calculator.selectedProduct) {
      this.decline();
      return;
    }

    this.loading = true;
    const metrics = marketingMetrics();
    const payload: ApplicationPayload = {
      productId: calculator.selectedProduct.id,
      input: {
        loanAmount: this.loanAmount.toString(10),
        term: this.term
      },
      purpose: calculation.loan.purpose
    };

    if (Object.keys(metrics).length) {
      payload.marketingMetrics = metrics;
    }

    try {
      await applications.initCreateApplication(payload);
      await this.redirect(REGISTRATION_ROUTES.DECISION_PAGE, { SEND_PROGRESS: SEND_PROGRESS });
    } catch (error) {
      const {
        headers,
        data: { status, detail, violations }
      } = error;

      if (status === 429) {
        const dateTime = nextTryDateTime(headers);
        const error = this.$t('FORM.ERROR_APPLICATION_REJECTED_429', { dateTime });
        errors.setError(error as string);
      } else {
        errors.setViolations(violations ?? [], detail);
      }

      await client.initProfile({ force: true });
      clearRoute();
      await this.redirect(this.previousPage, {});
    } finally {
      clearRoute();
      clearCalculation();
      this.loading = false;
    }
  }
}
