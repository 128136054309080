
import { Component, Mixins, Prop } from 'vue-property-decorator';
import ApplicationPage from '@/components/registration/ApplicationPage.vue';
import { ROUTE } from '@/router/routes_type';
import LmsDateInput from '@/components/registration/FieldDateInput.vue';
import Faq from '@/components/Faq.vue';
import LmsForm from '@/components/registration/Form.vue';
import LmsInput from '@/components/registration/FieldInput.vue';
import { filesRequired, financeMinFiveDigit, isDatePast, required } from '@/components/registration/settings/rules';
import { currencyMask } from '@/components/registration/settings/mask';
import { moneyPattern } from '@/components/registration/settings/pattern';
import { SECTIONS } from '@/store/modules/models/content';
import FinancesMixin from '@/views/registration/AdditionalInfo/FinancesMixin.vue';
import { applications } from '@/store/modules/application';
import LmsMultiInput from '@/components/registration/FieldMultiUpload.vue';

@Component({
  name: 'IncomeUploadPage',
  computed: {
    SECTIONS_FAQ() {
      return SECTIONS.FINANCES_FAQ;
    }
  },
  methods: { filesRequired, financeMinFiveDigit, moneyPattern, currencyMask, isDatePast, required },
  components: {
    LmsMultiInput,
    LmsInput,
    LmsForm,
    Faq,
    LmsDateInput,
    ApplicationPage
  }
})
export default class IncomeUploadPage extends Mixins(FinancesMixin) {
  @Prop({ required: true })
  page: ROUTE;

  async submitForm() {
    const applicationId = applications.lastApplication?.id;
    if (!applicationId) {
      return;
    }

    this.$emit('update:loading', true);
    try {
      const documents = await this.updateFinancesData(this.page, {
        applicationId: applicationId
      });
      this.$emit('submit', documents);
    } catch (error) {
      this.$emit('update:loading', false);
    }
  }
}
