
import { Component, Mixins } from 'vue-property-decorator';
import { PROFILE_ROUTES, REGISTRATION_ROUTES } from '@/router/routes_type';
import { SEND_PROGRESS, SKIP_ROUTE_CHECK } from '@/router/routes';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';

@Component
export default class ApplicationOfferMixin extends Mixins(CheckRoutingMixin) {
  async performAction(
    action: () => Promise<void>,
    changeLoadingState: (loading: boolean) => void,
    route: PROFILE_ROUTES | REGISTRATION_ROUTES
  ) {
    try {
      await action();
    } catch (e) {
      this.notifyErrors();
    } finally {
      await this.redirect(route, { SEND_PROGRESS: SEND_PROGRESS, SKIP_ROUTE_CHECK: SKIP_ROUTE_CHECK });
      changeLoadingState(false);
    }
  }

  private notifyErrors = () => this.$notify.error(this.$t('NOTIFICATION.APPROVE_OFFER.ERROR').toString());
}
