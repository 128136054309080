export enum AdditionalInfoSection {
  PERSONAL_DATA = 'personal-data',
  ADDRESS = 'address',
  FINANCES = 'finances',
  ADDITIONAL_INFO = 'additional-info'
}

type AvailableSectionsByStep = {
  [key in AdditionalInfoSection]?: AdditionalInfoSection[];
};

export const availableSectionsByStep: AvailableSectionsByStep = {
  [AdditionalInfoSection.PERSONAL_DATA]: [AdditionalInfoSection.PERSONAL_DATA],
  [AdditionalInfoSection.ADDRESS]: [AdditionalInfoSection.PERSONAL_DATA, AdditionalInfoSection.ADDRESS],
  [AdditionalInfoSection.FINANCES]: [
    AdditionalInfoSection.PERSONAL_DATA,
    AdditionalInfoSection.ADDRESS,
    AdditionalInfoSection.FINANCES
  ],
  [AdditionalInfoSection.ADDITIONAL_INFO]: [
    AdditionalInfoSection.PERSONAL_DATA,
    AdditionalInfoSection.ADDRESS,
    AdditionalInfoSection.FINANCES,
    AdditionalInfoSection.ADDITIONAL_INFO
  ]
};
