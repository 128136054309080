import { render, staticRenderFns } from "./BankCardPreview.vue?vue&type=template&id=64d8c74e"
import script from "./BankCardPreview.vue?vue&type=script&lang=ts"
export * from "./BankCardPreview.vue?vue&type=script&lang=ts"
import style0 from "./BankCardPreview.vue?vue&type=style&index=0&id=64d8c74e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports