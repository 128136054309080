
import { Component, Mixins } from 'vue-property-decorator';
import ApplicationPage from '@/components/registration/ApplicationPage.vue';
import { progress } from '@/store/modules/progress';
import request, { HTTP_METHODS } from '@/utils/request';
import { Agreement, AGREEMENT_CONTRACT_STATUS, ONLINE_SIGNING_TYPE } from '@/api/models/agreements';
import { production } from '@/utils/env';
import { DocumentService } from '@/services/document';
import { agreements } from '@/store/modules/agreements';
import { PROFILE_ROUTES, REGISTRATION_ROUTES } from '@/router/routes_type';
import { SEND_PROGRESS, SKIP_ROUTE_CHECK } from '@/router/routes';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';
import { getRouteByAgreement } from '@/router/router';

const POOLING_INTERVAL = 5000;

const APPROVED_CONTRACT_STATUS_LIST = [AGREEMENT_CONTRACT_STATUS.APPROVED, AGREEMENT_CONTRACT_STATUS.ISSUED];
const REJECT_CONTRACT_STATUS_LIST = [AGREEMENT_CONTRACT_STATUS.REJECTED, AGREEMENT_CONTRACT_STATUS.CANCELLED];

@Component({
  name: 'RegistrationAgreementOnlineSigningPage',
  components: { ApplicationPage }
})
export default class RegistrationAgreementOnlineSigningPage extends Mixins(CheckRoutingMixin) {
  content = '';
  documentId = '';
  agreementNumber = '';
  minHeight = '';
  loading: string[] = [];
  private fetchContentTimeout: number;
  private redirectTimeout: number;

  mounted() {
    progress.set('95');
  }

  async created() {
    await this.initContentFetchRepeat(0);
    await this.checkRedirect();
  }

  async open() {
    await this.handleDocument('opening', () => DocumentService.open(this.documentId));
  }

  async download() {
    await this.handleDocument('downloading', () => DocumentService.download(this.documentId));
  }

  async handleDocument(actionName: string, action: () => void) {
    if (this.loading.includes(actionName)) {
      return;
    }

    this.loading.push(actionName);
    try {
      await action();
    } catch ({ response }) {
      this.$notify.error(this.$t('NOTIFICATION.DOCUMENT_UPLOAD.ERROR').toString());
    } finally {
      this.loading = this.loading.filter(a => a !== actionName);
    }
  }

  loadIframe() {
    const iframe = document.getElementById('iframe') as HTMLIFrameElement;
    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;

    if (!iframeDoc) {
      return;
    }

    iframeDoc.getElementById('logo')?.parentElement?.classList.add('iframeLogo');
    iframeDoc.getElementById('files')?.classList.add('el-input__inner');
    const iframeSignButton = iframeDoc.getElementById('makewsign');
    iframeSignButton?.classList.add('el-button');
    iframeSignButton?.classList.add('el-button--primary');

    Array.from(iframeDoc.getElementsByName('pass4sign')).forEach(element => element.classList.add('el-input__inner'));
    Array.from(iframeDoc.getElementsByTagName('article')).forEach(element => (element.id = 'iframeArticle'));
    Array.from(iframeDoc.getElementsByTagName('main')).forEach(element => (element.id = 'iframeMain'));

    if (production()) {
      Array.from(document.getElementsByTagName('link'))
        .filter(element => element.href.endsWith('.css'))
        .forEach(element => iframeDoc.head.appendChild(element.cloneNode(true)));
    } else {
      Array.from(document.getElementsByTagName('style')).forEach(element =>
        iframeDoc.head.appendChild(element.cloneNode(true))
      );
    }

    setTimeout(() => {
      this.minHeight = iframeDoc.body.scrollHeight + 'px';
      iframe.style.opacity = '1';
    }, 200);
  }

  async initContentFetchRepeat(interval: number) {
    if (this.content.length === 0) {
      this.fetchContentTimeout = setTimeout(async () => {
        if (!(await agreements.initGetAgreementList({ force: true }))) {
          return;
        }

        await this.loadContent();
        await this.initContentFetchRepeat(POOLING_INTERVAL);
      }, interval);
    }
  }

  async loadContent() {
    await agreements.initPreparedAgreementDocuments();
    const agreement = agreements.getPreparedAgreement;

    this.content = await (agreement ? this.signOnlineIframeContent(agreement) : '');
  }

  async checkRedirect() {
    this.redirectTimeout = setTimeout(async () => {
      if (!(await agreements.initGetAgreementList({ force: true }))) {
        return;
      }

      let agreement = agreements.lastAgreement;
      if (agreement?.contractStatus && APPROVED_CONTRACT_STATUS_LIST.includes(agreement.contractStatus)) {
        return this.redirect(PROFILE_ROUTES.MY_LOAN_PAGE, { SEND_PROGRESS: SEND_PROGRESS });
      }

      const route = getRouteByAgreement(agreement);
      if (route && route !== REGISTRATION_ROUTES.AGREEMENT_ONLINE_SIGNING_PAGE) {
        return this.redirect(route);
      }

      if (agreement?.contractStatus && REJECT_CONTRACT_STATUS_LIST.includes(agreement.contractStatus)) {
        return this.redirect(REGISTRATION_ROUTES.APPLICATION_REJECT_PAGE, {
          SEND_PROGRESS: SEND_PROGRESS,
          SKIP_ROUTE_CHECK: SKIP_ROUTE_CHECK
        });
      }

      return this.checkRedirect();
    }, POOLING_INTERVAL);
  }

  async signOnlineIframeContent(agreement: Agreement) {
    const link = this.srcLink(agreement);

    if (!link) {
      return '';
    }

    return this.requestContent(link);
  }

  async requestContent(link: string) {
    return request({
      baseURL: process.env.VUE_APP_BASE_API_ANON,
      url: link,
      method: HTTP_METHODS.GET
    }).then(response => {
      return response.data
        .replaceAll(" src='gostjs", " src='https://api.podpishi.kz/gostjs")
        .replaceAll(' src="', ' src="https://api.podpishi.kz/')
        .replaceAll('/apisignmerchant.php', `${process.env.VUE_APP_BASE_API_ANON}/podpishi`);
    });
  }

  srcLink(agreement: Agreement) {
    const documents = agreements.getAgreementDocuments;

    if (agreement.signingMethod?.type !== ONLINE_SIGNING_TYPE || !documents[0]?.details.details?.signOnlineLink) {
      return null;
    }

    this.documentId = documents[0]?.documentId;
    this.agreementNumber = agreement.number;
    const url = documents[0]?.details.details?.signOnlineLink.split('?')[1];

    return `/podpishi?${url}`;
  }

  beforeDestroy() {
    clearTimeout(this.fetchContentTimeout);
    clearTimeout(this.redirectTimeout);
  }
}
