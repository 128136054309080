import i18n from '@/core/plugins/vue-i18n';
import { SelectSetting } from '@/components/registration/models/select';
import { InputSetting } from '@/components/registration/models/input';
import { bankAccountMask } from '@/components/registration/settings/mask';
import { bankAccountPattern, toUppercasePattern } from '@/components/registration/settings/pattern';
import { bankAccountNumberFormat, required } from '@/components/registration/settings/rules';
import { ClientDisbursement, DISBURSEMENT_TYPE } from '@/api/models/disbursement';
import { bankCardVerified } from '@/components/registration/disbursementType/bankCardAttachment/BankCardAttachment.values';

export interface DisbursementPayload {
  disbursement: ClientDisbursement;
}

export const disbursementTypeOptions = Object.values(DISBURSEMENT_TYPE);

export const disbursementSetting: SelectSetting = {
  options: () =>
    disbursementTypeOptions.map(value => {
      return { value, label: i18n.t(`FORM.OPTIONS.disbursementType.${value}`) };
    }),
  tooltip: true
};

export const bankAccountSetting: InputSetting = {
  tooltip: true,
  mask: bankAccountMask,
  pattern: bankAccountPattern,
  maskPattern: toUppercasePattern
};

export const registrationDisbursementRuleSet = {
  required,
  bankAccountNumberFormat,
  bankCardVerified
};
