
import { Component, Mixins, Prop, Ref } from 'vue-property-decorator';
import LmsForm from '@/components/registration/Form.vue';
import LmsFieldInput from '@/components/registration/FieldInput.vue';
import LmsFieldSelect from '@/components/registration/FieldSelect.vue';
import { saveDisbursementType, saveSigningAgreement } from '@/api/client';
import { LmsFormState } from '@/components/registration/models/form';
import { loader } from '@/utils/loader';
import LmsBankCardAttachment from '@/components/registration/disbursementType/BankCardAttachment.vue';
import {
  bankAccountSetting,
  DisbursementPayload,
  disbursementSetting,
  registrationDisbursementRuleSet
} from '@/components/registration/disbursementType/RegistrationDisbursementType.values';
import { DISBURSEMENT_TYPE, DisbursementCard } from '@/api/models/disbursement';
import { BankCardVerificationService } from '@/services/bankCard';
import { ROUTE } from '@/router/routes_type';
import CheckRoutingMixin from '@/mixins/CheckRouting.vue';
import { client } from '@/store/modules/client';
import { SelectSetting } from '@/components/registration/models/select';
import { ContentService } from '@/services/content';
import { SECTIONS } from '@/store/modules/models/content';
import { required } from '@/components/registration/settings/rules';

@Component({
  name: 'RegistrationDisbursementTypePage',
  components: {
    LmsBankCardAttachment,
    LmsForm,
    LmsFieldInput,
    LmsFieldSelect
  }
})
export default class RegistrationDisbursementTypePage extends Mixins(CheckRoutingMixin) {
  @Ref('disbursementForm') form!: LmsFormState;
  @Prop({ required: true })
  page: ROUTE;

  disbursementSelected = this.initialDisbursement || '';
  loading = true;
  initialBankAccount = '';
  initialCard: DisbursementCard | null = null;
  signMethodType: string;

  signingAgreementSetting: SelectSetting = {
    options: () => ContentService.sortedOptions(SECTIONS.AGREEMENT_SIGNING_TYPE),
    tooltip: true
  };

  selectRules = { required };
  selectSignMethodType(type: string) {
    this.signMethodType = type;
  }
  async created() {
    await client.initProfileDisbursements();
    this.initialBankAccount = client.currentBankAccountNumber ?? 'KZ';
    this.initialCard = client.bankCardSelected;
    this.loading = false;
  }

  get isBank() {
    return this.disbursementSelected === DISBURSEMENT_TYPE.BANK_ACCOUNT;
  }

  get isCard() {
    return this.disbursementSelected === DISBURSEMENT_TYPE.BANK_CARD;
  }

  get disbursementTypeFieldSettings() {
    return {
      ...disbursementSetting,
      initial: this.initialDisbursement
    };
  }

  get initialDisbursement(): DISBURSEMENT_TYPE {
    const verificationId = BankCardVerificationService.catchVerificationId(this.$route);
    if (verificationId) {
      return DISBURSEMENT_TYPE.BANK_CARD;
    } else {
      return this.disbursementSelected || disbursementSetting.initial;
    }
  }

  ruleSet() {
    return registrationDisbursementRuleSet;
  }

  bankAccountFieldSettings() {
    return bankAccountSetting;
  }

  selectDisbursementType(disbursement: DISBURSEMENT_TYPE) {
    this.disbursementSelected = disbursement;
  }

  async submit(payload: DisbursementPayload) {
    this.loading = true;
    const route = await this.checkRouteBeforeProgress(this.page);
    if (route) return this.redirect(route);

    try {
      await Promise.all([
        saveDisbursementType(payload.disbursement, true),
        saveSigningAgreement({ type: this.signMethodType })
      ]);
      client.clearCurrentCardDisbursement();
      await loader.show();
      this.$emit('submit');
    } catch ({ data }) {
      this.form.setErrors(data.violations);
    } finally {
      this.loading = false;
    }
  }
}
